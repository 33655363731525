import React from "react";
import { MenuItem, Select } from "@material-ui/core";

interface YearSelectorProps {
    year: number;
    yearsArray?: number[];
    handleSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    className?: string;
}

function YearSelector({ year, yearsArray, handleSelect, className }: YearSelectorProps) {
    const renderYearOptions = () => {
        return yearsArray ? (
            yearsArray.map((yearOption) => (
                <MenuItem value={yearOption} key={yearOption}>
                    {yearOption}
                </MenuItem>
            ))
        ) : (
            <MenuItem value={year}>{year}</MenuItem>
        );
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        handleSelect(event as React.ChangeEvent<HTMLSelectElement>);
    };

    return (
        <Select
            style={{ marginRight: 8, fontSize: 18, fontWeight: 600 }}
            value={year}
            onChange={handleChange}
            className={className}
        >
            {renderYearOptions()}
        </Select>
    );
}

export default YearSelector;
